import React from 'react';
// import { Link } from 'react-router-dom';
// import { CognitoUser, AuthenticationDetails } from 'amazon-cognito-identity-js';
// import UserPool from './cognito.service'
import swal from 'sweetalert';
import { API_URL, API_PRODUCCION } from '../../components/helpers/resources'
import './login.css'
class Login extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            username: '',
            password: '',
            loading: false,
            id_usuario: '',
            id_institucion: '',
            id_rol: ''
        }
        this.changeUsername = this.changeUsername.bind(this);
        this.changePassword = this.changePassword.bind(this);
        this.getRol = this.getRol.bind(this);
        this.handleLogin = this.handleLogin.bind(this);

    }

    componentDidMount() {
        localStorage.getItem('accessToken');
    }

    changeUsername(event) {
        let username = event.target.value.replace(' ', '')
        this.setState({ username: username.toLocaleLowerCase() });
    }

    changePassword(event) {
        this.setState({ password: event.target.value })
    }

    async handleLogin(event) {
        event.preventDefault()
        // Peticiones
        const infoUsuario = await this.obtenerInfoUsuario(this.state.username, this.state.password)
        var usuario = await infoUsuario
        const peticion = await this.getRol(usuario.id_usuario)

        if (infoUsuario.id_usuario) {
            localStorage.setItem("usuario", JSON.stringify(usuario))
            localStorage.setItem('rol', JSON.stringify({
                rol: peticion.txt.rol !== "Super-Admin" ? false : true
            }))
        } if(usuario.id_rol === 3 || usuario.id_rol === 2 ||  usuario.id_rol === 1) {
            swal({
                title: 'Error',
                text: "No tiene permitido el ingreso",
                icon: "error",
                dangerMode: true,
            })
        }else{
            window.location.href = '/instituciones'
        }
    }
        
    async obtenerInfoUsuario(usuario, clave) {
        const response = await fetch(`${API_PRODUCCION}/usuario_login`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                usuario_cognito: usuario,
                usuario_password: clave
            })
        })
        if (response.ok) {
            const json = await response.json();
            return json;
        }
        else {
            const error = await response.json();
            swal({
                title: 'Ingreso',
                text: error.txt,
                icon: "error",
                dangerMode: true,
            })
        }
        return null;
    }

    iniciarSIE() {
        var iframe = document.createElement('iframe');
        iframe.src = "https://sie.educar.com.co/cas/login?service=http%3A%2F%2Fsie.educar.com.co%2FAprende%2F&logueo=true&user=demo.plataforma&pass=demo.plataforma"
    }

    render() {

        return (
            <div className="background">
                <form className="form" onSubmit={(e) => this.handleLogin(e)}>
                    <h2>Iniciar sesion</h2>
                    <p type="Nombre de usuario:"><input placeholder="Nombre de usuario" value={this.state.username} onChange={this.changeUsername} name="username" /></p>
                    <p type="Contraseña:"><input placeholder="Contraseña" value={this.state.password} onChange={this.changePassword} name="password" type="password" /></p>
                    <button type="submit" className="btn btn-primary btn-rounded btn-form-login">
                        {
                            this.state.loading 
                                ?
                                    <div className="spinner-border spinner-border-sm" role="status" ></div>
                                :
                                    <span>INICIAR SESIÓN</span>
                        }
                    </button>
                </form>
                <span style={{color:'#555'}}>1.0.0</span>
            </div>
        );
    }


    async getRol(id_usuario) {
        const response = await fetch(`${API_URL}/consultar/rol`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                usuario_id: id_usuario,
            })
        })
        const json = await response.json();
        return json
    }

}

export default Login;